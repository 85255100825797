<style lang="less" scoped>
  .pfm-item {
    padding: 20px;
    background-color: #FFF;
    transition: all .3s;
    border-radius: 5px;
    border: 1px solid #EEE;
    &.pointer {
      cursor: pointer;
      &:hover {
        border-color: #bbb;
      }
    }
    .month {
      font-size: 40px;
      font-weight: bold;
      color: #000;
      display: inline-block;
    }
    .label {
      margin-left: 8px;
      color: #000;
    }
    .info {
      user-select: none;
      position: relative;
      transition: all .3s;
    }
  }
  .flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>

<style>
  .pfm-item .ivu-tag {
    border: none !important;
  }
</style>

<template>
  <div class="pfm-item pointer" v-loading="loading" @click="itemClick">
    <div class="info">
      <div class="flex-between">
        <div>
          <Tooltip :content="item.month" placement="right">
            <span class="month">{{item.web.month}}</span>
            <span class="label">月</span>
          </Tooltip>
        </div>
        <Tag size="large" v-if="item.status === 'wait'" color="default">未上传</Tag>
        <Tag size="large" v-else-if="item.status === 'check'" color="purple">已上传</Tag>
        <Tag size="large" v-else-if="item.status === 'end'" color="geekblue">已发布</Tag>
      </div>
      <Row type="flex" align="middle" justify="space-between" :wrap="false">
        <i-col>
          <Tooltip class="block" :content="item.remark || '暂无备注'" placement="top-start">
            <span>备注</span>
          </Tooltip>
        </i-col>
        <i-col>
          <span v-if="item.type === 'compute'">测算</span>
          <span v-if="item.type === 'real'">实际</span>
        </i-col>
      </Row>
    </div>
  </div>
</template>

<script>
import { request } from '@/api'

export default {
  props: {
    item: { type: Object, default: null }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async itemClick () {
      let confirm = true
      let id = this.item.id
      if (!id && this.$authFunsProxyNoRoute['performance.wages.manage.index-manageMonth']) {
        confirm = await this.$confirm({
          title: '系统提示',
          content: '确定新增' + this.item.web.month + '月工资吗？'
        })
        if (confirm) {
          const data = await request('/performance/wage_month', 'post', {
            month: this.$datetime.format(this.item.month, 'Y-M-D'),
            remark: ''
          })
          id = data.id
        } else {
          return
        }
      }
      if (this.$authFunsProxyNoRoute['performance.wages.manage.index-getDetail']) {
        this.$router.push({
          name: 'performance.wages.manage.detail',
          query: {
            id
          }
        })
      }
    }
  }
}
</script>
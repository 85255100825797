<style lang="less" scoped>
  .top {
    display: flex;
    align-items: center;
    background-color: #FFF;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 10px 0 10px;
  }
  .content {
    margin: 5px;
    height: 0;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .calculate-page {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .wrap {
    width: 25%;
    padding: 5px;
    box-sizing: border-box;
  }
  .flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .flex-center {
    display: flex;
    align-items: center;
  }
</style>

<style>
  .calculate-page .years .ivu-tag {
    cursor: pointer;
    border: none !important;
  }
</style>

<template>
  <div class="calculate-page">
    <div class="top flex-between">
      <div style="flex: 1;margin-right: 10px;" class="flex-center">
        <fm-title title-text="工资管理"></fm-title>
        <DatePicker @on-change="loadData" type="year" placeholder="请选择年份" v-model="query.year" style="width: 200px"></DatePicker>
      </div>
      <div>
        <Button icon="md-refresh-circle" v-if="$authFunsProxyNoRoute['performance.wages.manage.index-getMonth']" @click="loadData" :loading="loading.load">刷新</Button>
        <Poptip style="margin-left: 10px;" placement="left-start" title="新增月度工资" v-if="isManage && $authFunsProxyNoRoute['performance.wages.manage.index-manageMonth']">
          <Button icon="md-add-circle" ref="addbtn" :loading="loading.add" type="primary">新增</Button>
          <div slot="content">
            <Form>
              <FormItem>
                <DatePicker v-model="formData.month" type="month" placeholder="请选择工资月份" />
              </FormItem>
              <FormItem>
                <Input type="textarea" v-model="formData.remark" placeholder="请输入备注" />
              </FormItem>
            </Form>
            <Button :loading="loading.add" @click="addSubmit" long type="primary" style="display: block;">确定新增</Button>
          </div>
        </Poptip>
      </div>
    </div>
    <div class="content scrollbar">
      <TableEmpty style="display: flex;flex-direction: column;align-items: center;background: #FFF;justify-content: center;width: 100%;height: 100%;border-radius: 5px;" v-if="!monthList.length" />
      <div class="wrap" v-for="item in monthList" :key="item.month">
        <PfmItem :item="item" @reload="loadData" />
      </div>
    </div>
  </div>  
</template>

<script>
import { request } from '@/api'
import TableEmpty from '@/components/base/TableEmpty.vue'
import PfmItem from './items/index.vue'

import { getEmptyMonthItem } from './lib'

function getDefaultFormData () {
  return {
    month: new Date(),
    remark: ''
  }
}

export default {
  name: 'CalculatePage',
  components: { TableEmpty, PfmItem },
  data () {
    return {
      loading: {
        load: false,
        add: false
      },
      dataList: [],
      formData: getDefaultFormData(),
      status: {
        form: false
      },
      query: {
        year: new Date()
      }
    }
  },
  computed: {
    monthList () {
      const monthList = this.dataList
      const month = monthList.map(v => v.web.month)
      const fill = []
      for (let i = 1; i <= 12; i++) {
        if (!month.includes(i)) {
          fill.push(getEmptyMonthItem(this.query.year.getFullYear() + '-' + (i < 10 ? ('0' + i) : i) + '-01'))
        }
      }
      return [...monthList, ...fill].sort((a, b) => a.web.month - b.web.month)
    },
    isManage () {
      return this.$authFunsProxy.manageMonth
    }
  },
  methods: {
    async addSubmit () {
      if (!this.formData.month) {
        return this.$Message.error('请选择月份')
      }
      try {
        this.loading.add = true
        await request('/performance/wage_month', 'post', {
          month: this.$datetime.format(this.formData.month, 'Y-M-D'),
          remark: this.formData.remark
        })
        this.formData.remark = ''
        this.loading.add = false
        this.$Message.success('添加成功')
        this.loadData()
        this.$refs.addbtn.$el.click()
      } catch (e) {
        this.loading.add = false
        this.$Modal.error({ title: '添加失败', content: e.message })
        console.error(e)
      }
    },
    async loadData () {
      if (!this.$authFunsProxyNoRoute['performance.wages.manage.index-getMonth']) {
        return
      }
      try {
        this.$Loading.start()
        this.loading.load = true
        const dataList = await request('/performance/wage_month', 'get', {
          year: this.query.year.getFullYear()
        })
        dataList.forEach(v => {
          const split = v.month ? v.month.split('-') : [null, null]
          v.web = {
            year: Number(split[0]),
            month: Number(split[1])
          }
        })
        this.dataList = JSON.parse(JSON.stringify(dataList))
        this.loading.load = false
        this.$Loading.finish()
      } catch (e) {
        this.$Loading.error()
        console.error(e)
        this.loading.load = false
        this.$Modal.error({ title: '加载失败', content: e.message })
      }
    },
  },
  mounted () {
    this.loadData()
  }
}
</script>
export function getEmptyMonthItem (month) {
  const spl = month.split('-')
  return {
    id: null,
    month: month,
    status: 'wait',
    type: 'compute',
    remark: '',
    web: {
      year: Number(spl[0]),
      month: Number(spl[1])
    }
  }
}